<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange"
                         size='small' style="margin-right: 20px"></el-cascader>
            <el-button type="primary" size='small' plain @click="showSearch">查询</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
            <el-button plain size='small' class='fr' type="primary" @click="handleExport">导出</el-button>
        </div>

        <el-card class="box-card">
            <el-table :data="recordList" size="small" class="maxWidth" stripe :height='tableHeight'>
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="no" label="挂号单号" align="center"></el-table-column>
                <el-table-column prop="wxRecordId" label="微信支付单号" align="center" ></el-table-column>
                <el-table-column prop="amount"  label="支付金额" align="center" :formatter="formatterAmount"></el-table-column>
                <el-table-column prop="type" width="100" label="支付类型" align="center" :formatter="formatterType"></el-table-column>
                <el-table-column prop="createTime" label="支付时间" align="center"></el-table-column>
            </el-table>
        </el-card>

        <div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='sizeChange' @currentChange='currentChange'></pagi-nation>
        </div>

        <!-- 查询 -->
        <el-dialog title="查询" v-dialogDrag :visible.sync="isShowSearchRecordDlg" width="40%">
            <el-form :model="searchForm" size='small' label-width="100px">
                <el-row>
<!--                    <el-col :sm="24">-->
<!--                        <el-form-item label="姓名：">-->
<!--                            <el-input v-model="searchForm.name" clearable placeholder="患者姓名"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :sm="24">-->
<!--                        <el-form-item label="手机号：">-->
<!--                            <el-input :maxlength=11 v-model="searchForm.phone" clearable placeholder="患者手机号"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :sm="24">-->
<!--                        <el-form-item label="挂号科室：">-->
<!--                            <el-select v-model="searchForm.epartmentId" clearable placeholder="请选择" class="w100">-->
<!--                                <el-option-->
<!--                                        v-for="item in registeredDepart"-->
<!--                                        :key="item.value"-->
<!--                                        :label="item.label"-->
<!--                                        :value="item.value"-->
<!--                                ></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :sm="24">-->
<!--                        <el-form-item label="挂号状态：">-->
<!--                            <el-select v-model="searchForm.registeredStatus" clearable placeholder="请选择" class="w100">-->
<!--                                <el-option-->
<!--                                        v-for="item in global.DictionaryData['registeredStatus']"-->
<!--                                        :key="item.value"-->
<!--                                        :label="item.label"-->
<!--                                        :value="item.value"-->
<!--                                ></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :sm="24">-->
<!--                        <el-form-item label="是否就诊：">-->
<!--                            <el-select v-model="searchForm.confirmState" placeholder="请选择" class='maxWidth'-->
<!--                                       size='small'>-->
<!--                                <el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value"-->
<!--                                           :label="item.label" :value="item.value"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :sm="24">
                        <el-form-item label='时间'>
                            <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" :clearable="false"
                                            @change='dataChange' v-model="dateValue" type="daterange" align="right"
                                            unlink-panels range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isShowSearchRecordDlg = false" size='small'>取消</el-button>
                <el-button type="primary" @click="getRecordList" size='small'>确定</el-button>
            </span>
        </el-dialog>


        <!-- 导出 -->
        <el-dialog v-dialogDrag title="导出" :visible.sync="isShowExportDlg">
            <el-form :model="exportItem" label-width="100px" ref="exportItem">
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="开始日期" prop="beginDate">
                            <el-date-picker
                                    size="small"
                                    v-model="exportItem.beginDate"
                                    type="date"
                                    placeholder="选择开始日期"
                                    style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="截止日期" prop="endDate">
                            <el-date-picker
                                    size="small"
                                    v-model="exportItem.endDate"
                                    type="date"
                                    placeholder="选择结束日期"
                                    style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button plain size="small" @click="isShowExportDlg = false">取 消</el-button>
                <el-button plain size="small" type="primary" @click="exportList">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    class Record {
        constructor() {
            this.patientName = null;
            this.phone = null;
            this.departmentId = null;
            this.registeredStatus = null;
            this.confirmState = null;
            this.beginDate = null;
            this.endDate = null;
            this.sourceType = 0;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        name: 'permissionConfig',
        data() {
            return {
                recordList: [],
                tableHeight: document.documentElement.clientHeight - 288,
                page: new this.Page(),

                isShowSearchRecordDlg: false,
                searchForm: new Record(),

                rules: {
                    name: [{required: true, message: '角色名称是必须的', trigger: 'blur'}],
                    keyWord: [{required: true, message: '关键字是必须的，且不可重复', trigger: 'blur'}],
                },

                registeredDepart: [],//预约科室

                dateValue: [],

                isShowDetailDlg: false,
                medicalRecord: [],

                isShowExportDlg: false,
                exportItem: new Record(),
            }
        },
        computed: {
        },
        methods: {
            formatterAmount(row, column) {
                return row.amount? '￥'+Number(row.amount).toFixed(2) + '元' : '￥0.00元';
            },
            formatterType(row,column){
                return row.type=='refund'?'退款':'支付';
            },
            //导出
            handleExport() {
                this.exportItem = this.deepClone(this.searchForm);
                this.isShowExportDlg = true;
            },
            //下载文件
            download(data,name){
                if (!data) {
                    return
                }
                let url = window.URL.createObjectURL(new Blob([data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;

                link.setAttribute('download', name);

                document.body.appendChild(link);
                link.click();
                this.isShowImportDialog = false;
            },
            // download(data, exportFileName) {
            //     if (!data) {
            //         return
            //     }
            //     let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}));
            //     let link = document.createElement('a');
            //     link.style.display = 'none';
            //     link.href = url;
            //     link.setAttribute('download', exportFileName);
            //     document.body.appendChild(link);
            //     link.click();
            //
            //     this.isShowExportDlg = false;
            // },
            //导出
            async exportList () {
                let formData = {};
                formData.orgId = this.deepClone(this.global.searchOrgId);
                formData.beginDate = this.exportItem.beginDate? this.getDate(this.exportItem.beginDate): null;
                formData.endDate = this.exportItem.endDate? this.getDate(this.exportItem.endDate): null;
                if (formData.orgId && formData.orgId.length) {
                    formData.orgId = formData.orgId.pop()
                }
                let res = await this.$http.downloadFile('/pay/exportRecord', formData);
                if (res) {
                    this.download(res,'动账记录('+formData.beginDate+'至'+formData.endDate+').xlsx');
                }
            },
            dataChange(val) {
                if (!val) {
                    this.dateValue = [];
                }
                this.searchForm.beginDate = this.dateValue[0];
                this.searchForm.endDate = this.dateValue[1];
            },
            showSearch() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                this.isShowSearchRecordDlg = true;
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.getRecordList();
                    this.getDepartment();
                }
            },
            handleDetail(row) {
                this.$router.push({name: 'roleDetail', params: {id: row.id}});
            },
            sizeChange(val) {
                this.page.currentPage = 1;
                this.page.pageSize = val;
                this.getRecordList();
            },
            currentChange(val) {
                this.page.currentPage = val;
                this.getRecordList();
            },
            searchRecord() {
                this.page.reset();
                this.getRecordList();
                this.isShowSearchRecordDlg = false;
            },
            async getRecordList() {
                this.isShowSearchRecordDlg = false;
                let orgId = this.global.orgId[this.global.orgId.length-1];
                this.searchForm.orgId = orgId;
                this.searchForm.limit = this.page.pageSize;
                this.searchForm.offset = (this.page.currentPage - 1) * this.page.pageSize;
                let res = await this.$http.post('/pay/getRecord', this.searchForm);
                if (res.state) {
                    for (let item of res.data.list) {
                        item.createTime = this.getDateTime(item.createTime);
                    }
                    this.recordList = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            async getDepartment() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    registeredType: 0
                };
                let res = await this.$http.post('/sys/getRegisteredHospitalDep', param);
                if (res.state) {

                    this.registeredDepart = [];
                    if (res.data) {
                        this.registeredDepart = res.data;
                    }
                }
            },
            async handleView(row) {
                console.log(row);
                let str = row.registeredDate;
                let date = str.substring(0,str.length-2);
                let param = {
                    beginDate: this.getDateTime(date),
                    endDate: this.getDateTime(this.getAfterForDate(date,1)),
                    idNumber: row.idNumber,
                    orgId: row.orgId,
                    patientName:row.patientName
                };
                let res = await this.$http.post('/registered/getPatientMedicalRecord',param);
                if(res.state){
                    if(res.data){
                        this.medicalRecord = res.data;
                        this.isShowDetailDlg = true;
                    }
                }
            },
            confirmVisit() {

            },
            refresh() {
                this.page.reset();
                this.searchForm.reset();
                let startTime = this.getStartDateOfMonth();
                let endTime = this.getDate();
                this.dateValue = [startTime, endTime];
                this.searchForm.beginDate = this.dateValue[0];
                this.searchForm.endDate = this.dateValue[1];
                this.getRecordList();
            },
            init() {
                if (this.global.orgId.length) {
                    this.getRecordList();
                    this.getDepartment();
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            let startTime = this.getStartDateOfMonth();
            let endTime = this.getDate();
            this.dateValue = [startTime, endTime];
            this.searchForm.beginDate = this.dateValue[0];
            this.searchForm.endDate = this.dateValue[1];
            this.init()
            /*this.getoptions('registeredStatus');
            this.getoptions('medicalType');*/
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }

</script>

<style>
</style>
